import Cookies from 'js-cookie';
import { url } from './url';

export const storeCookie = (data: any, sessionTimeout: any) => {
    // session
    Cookies.set('sessionTimeout', sessionTimeout, {
        domain: url?.domains?.main,
        path: '/',
        secure: true,
        sameSite: 'Strict',
    });

    Cookies.set('sessionTimeout', sessionTimeout, {
        domain: url?.domains?.lucknow,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    Cookies.set('sessionTimeout', sessionTimeout, {
        domain: url?.domains?.kanpur,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    Cookies.set('sessionTimeout', sessionTimeout, {
        domain: url?.domains?.agra,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    // userdetail
    Cookies.set('userdetail', JSON.stringify(data), {
        domain: url?.domains?.main,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    Cookies.set('userdetail', JSON.stringify(data), {
        domain: url?.domains?.lucknow,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    Cookies.set('userdetail', JSON.stringify(data), {
        domain: url?.domains?.kanpur,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    Cookies.set('userdetail', JSON.stringify(data), {
        domain: url?.domains?.agra,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    // token
    Cookies.set('token', data?.access, {
        domain: url?.domains?.main,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    Cookies.set('token', data?.access, {
        domain: url?.domains?.lucknow,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    Cookies.set('token', data?.access, {
        domain: url?.domains?.kanpur,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });

    Cookies.set('token', data?.access, {
        domain: url?.domains?.agra,
        path: '/',
        secure: true,
        sameSite: 'Strict'
    });
}