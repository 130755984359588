import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dataActions } from "../../shared/store/data-Slice";
import { uiActions } from "../../shared/store/ui-Slice";
import { CloseBtn, Warn } from "../svg/AllSvg";

const ImpNotice = () => {
    const impNotice: any = useSelector((state: any) => state.data.impNotice);
    const link: string = useSelector((state: any) => state.data.link);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setImpNotice(false))
            dispatch(dataActions.setLink(null));
        }
    };

    const handleLink = (item: any) => {
        dispatch(uiActions.setWebOpen(true));
        dispatch(dataActions.setLink(item));
    }

    return (
        <>
            {impNotice &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(88,88,88,0.40)] backdrop-blur-[2.5px]" onClick={() => { dispatch(dataActions.setImpNotice(false)); dispatch(dataActions.setLink(null)); }}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative m-[15px] mm:mx-auto bg-white rounded-[10px] w-full md:w-[783px] h-[500px] xlx:h-[615px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12))]">
                            <div className="w-full flex items-center justify-between py-[18px] px-[22px]">
                                <div className="flex items-center gap-[8px]">
                                    <Warn />
                                    <h4 className="text-[#F00000c7] font-bold">{lang === "en" ? "Important Notice" : "महत्वपूर्ण सूचना"}</h4>
                                </div>
                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" onClick={() => dispatch(dataActions.setImpNotice(false))}>
                                    <CloseBtn />
                                </button>
                            </div>

                            <div className="w-full h-[calc(100%-61px)] flex flex-col justify-between rounded-b-[10px]">
                                <div className="h-full overflow-y-scroll w-full flex flex-col gap-[24px] px-[13px]">
                                    {lang === "en" ? <div className="bg-[#D9D9D9] bg-opacity-[0.21] px-[31px] py-[20px]">
                                        <h5 className="font-bold text-[#5C5C5C] mb-[4px]">Don’t be a victim of Employment Fraud</h5>

                                        <ul className="ml-[25px] flex flex-col gap-[2px]">
                                            <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">Beware of unscrupulous and fraudulent person (s)/ agencies sending fake emails/ offers of
                                                appointments/ extending false job promises through social media etc purporting to be from
                                                Uttar Pradesh Metro Rail Corporation (UPMRC).
                                            </li>

                                            <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">Don’t fell prey to such person (s)/ agencies who are falsely stated to be officials of UPMRC
                                                asking for money for securing jobs in UPMRC.
                                            </li>

                                            <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">The general public is hereby informed that UPMRC conducts recruitment in a fair and
                                                transparent manner. All recruitment notices are hosted on UPMRC official website i.e. <Link className="text-primary hover:underline focus:underline font-medium text-sm leading-snug" to="www.upmetrorail.com" target="_blank" onClick={(e) => { handleLink("www.upmetrorail.com"); e.preventDefault(); }}>www.upmetrorail.com</Link> / <Link className="text-primary hover:underline focus:underline font-medium text-sm leading-snug" to="www.lmrcl.com" target="_blank" onClick={(e) => { handleLink("www.lmrcl.com"); e.preventDefault(); }}>www.lmrcl.com</Link>. UPMRC does not solicit any money whatsoever from
                                                any candidate at any stage of the recruitment process, other than the prescribed application
                                                fee, if mentioned in such job advertisements.</li>

                                            <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">Therefore, the general public, is hereby cautioned not to become a victim of employment
                                                fraud to such bogus person (s)/ agencies aiming at swindling money from the innocent
                                                public through emails, social media etc. and urges public not to believe any such promise of
                                                securing jobs in UPMRC.</li>

                                            <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">UPMRC will not be responsible in any way for any amount paid by any individual to such
                                                person (s)/ agencies purporting to be representing UPMRC.</li>
                                        </ul>

                                        <h6 className="font-semibold text-[#5C5C5C] text-sm pt-[20px]">I CONFIRM THAT I HAVE READ AND UNDERSTOOD THE ABOVE CONTENTS
                                        </h6>
                                    </div> :
                                        <div className="bg-[#D9D9D9] bg-opacity-[0.21] px-[31px] py-[20px]">
                                            <h5 className="font-bold text-[#5C5C5C] mb-[4px]">रोजगार मे होने वाली धोखाधड़ी का शिकार न बनें</h5>

                                            <ul className="ml-[25px] flex flex-col gap-[2px]">
                                                <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">उत्तर प्रदेश मेट्रो रेल कॉरपोरेशन से होने का दावा करते हुए फर्जी ईमेल/नियुक्ति के प्रस्ताव/सोशल मीडिया
                                                    आदि के माध्यम से झूठी नौकरी के वादे करने वाले बेईमान और धोखाधड़ी करने वाले व्यक्तियों/एजेंसियों से
                                                    सावधान रहे।
                                                </li>

                                                <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">ऐसे व्यक्तियों/एजेंसियों का शिकार बिल्कुल न बने, जो स्वयं को उत्तर प्रदेश मेट्रो रेल कॉरपोरेशन का
                                                    अधिकारी बताकर नौकरी सुनिश्चित करने का दावा करते हुए पैसो की मांग करते हैं।
                                                </li>

                                                <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">जनसामान्य को सूचित किया जाता है कि उत्तर प्रदेश मेट्रो रेल कॉरपोरेशन भर्ती प्रक्रिया को पारदर्शी और
                                                    निष्पक्ष तरीके से संचालित करती है। उत्तर प्रदेश मेट्रो विभागीय भर्ती से संबंधित सभी सूचनाएँ अपनी
                                                    आधिकारिक वेबसाइट <Link className="text-primary hover:underline focus:underline font-medium text-sm leading-snug" to="www.upmetrorail.com" target="_blank" onClick={(e) => { handleLink("www.upmetrorail.com"); e.preventDefault(); }}>www.upmetrorail.com</Link> / <Link className="text-primary hover:underline focus:underline font-medium text-sm leading-snug" to="www.lmrcl.com" target="_blank" onClick={(e) => { handleLink("www.lmrcl.com"); e.preventDefault(); }}>www.lmrcl.com</Link> पर प्रकाशित करती है। किसी भी
                                                    उम्मीदवार से भर्ती प्रक्रिया के किसी भी चरण में कोई भी पैसा नहीं मांगा जाता है, सिवाय निर्धारित आवेदन
                                                    शुल्क के, जो नौकरी के विज्ञापनों में उल्लेखित किया जाता है।</li>

                                                <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">अत: जनसामान्य को सूचित किया जाता है कि वे ऐसे फर्जी व्यक्तियों/एजेंसियों का शिकार बनने से बचे जो
                                                    ईमेल, सोशल मीडिया आदि के माध्यम से जनता से पैसे ठगने का काम कर रहे हैं।</li>

                                                <li className="font-semibold text-[#5C5C5C] text-sm leading-snug list-disc">उत्तर प्रेदश मेट्रो रेल कॉरपोरेशन ऐसे किसी भी व्यक्ति या एजेंसी को दिए गए पैसे के भुगतान के लिए
                                                    किसी भी प्रकार से जिम्मेदार नहीं होगा।</li>
                                            </ul>

                                            <h6 className="font-semibold text-[#5C5C5C] text-sm pt-[20px]">मैं पुष्टि करता हूँ कि मैंने उपरोक्त सूचना को पढ़ लिया है और समझ लिया है।
                                            </h6>
                                        </div>
                                    }
                                </div>

                                <div className="px-[31px] flex items-end justify-end py-[24px]">
                                    <button className="rounded-[10px]  h-[40px] w-[139px] shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm font-bold !bg-primary disabled:opacity-[0.3] disabled:cursor-not-allowed   hover:shadow-[0_0_6px_0px_#61095A] focus:shadow-[0_0_6px_0px_#61095A]" onClick={() => { if (!link?.includes("careers")) { dispatch(dataActions.setImpNotice(false)); dispatch(uiActions.setWebOpen(true)); } else { navigate(link); dispatch(dataActions.setImpNotice(false)); dispatch(dataActions.setLink(null)); } }} autoFocus={true}>{lang === "en" ? "Understood" : "समझ गया"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default ImpNotice;