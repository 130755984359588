/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unreachable */
import { useTranslation } from "react-i18next";
import { Logout, Profile, ProfileArr } from "../svg/AllSvg";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../shared/store/auth-Slice";
import { Link } from "react-router-dom";
import { dataActions } from "../../shared/store/data-Slice";
import useAxios from "../../shared/hooks/use-axios";
import { url } from "../../shared/utils/url";

const ProfilePopup = () => {
    const { t } = useTranslation('common');
    const lang: string = useSelector((state: any) => state.ui.lang);
    const user: any = useSelector((state: any) => state.auth.user);
    const dispatch = useDispatch();

    const handleLogoutApi = async () => {
        try {
            const res: any = await useAxios.post(`/${lang + url?.logout}`, { "refresh_token": user?.refresh });
            if (res && res?.status === 200) {
                return true;
            }
        } catch (error) {
            return false;
            console.error("error while logout", error);
        }
    }

    const handleLogout = async () => {
        const success: any = await handleLogoutApi();
        if (success) {
            dispatch(dataActions.setProfile(false))
            dispatch(authActions.logout());
            dispatch(authActions.userDetail(null))
        }
    }

    return (
        <div className='absolute top-[35px] right-[15px] rounded-[10px] bg-white shadow-[0px_14px_64px_-4px_rgba(24,39,75,0.12),0px_8px_22px_-6px_rgba(24,39,75,0.12)] min-w-[232px] py-[22px] z-[32] flex flex-col '>
            <div className="flex items-center gap-[11px] px-[22px] sm:flex-row flex-col">
                <div className="min-w-[45px]">
                    <Profile />
                </div>
                <div className="flex flex-col items-center sm:items-start">
                    <h2 className="text-[#121] text-sm font-bold">{user?.first_name + " " + user?.last_name}</h2>
                    <p className="text-[#121] text-xs font-normal opacity-[0.75]">{user?.email}</p>
                </div>
            </div>

            <div className="px-[22px]">
                <div className="border-b border-[#121] border-opacity-[0.25] w-full my-[12px]"></div>
            </div>

            {/* <div className="flex flex-col">
                <Link to="" className="flex items-center justify-between w-full cursor-pointer hover:bg-[#f4f4f4] focus:bg-[#f4f4f4]   px-[22px] py-[6px]">
                    <div className="flex items-center gap-[8px]">
                        <MyAcoount />
                        <p className="text-darkGray text-xs font-normal">{t("auth.profile.my_account")}</p>
                    </div>

                    <ProfileArr />
                </Link> */}

            {/* <Link to="" className="flex items-center justify-between w-full cursor-pointer hover:bg-[#f4f4f4] focus:bg-[#f4f4f4]   px-[22px] py-[6px]">
                    <div className="flex items-center gap-[8px]">
                        <MyTicket />
                        <p className="text-darkGray text-xs font-normal">{t("auth.profile.my_tickets")}</p>
                    </div>

                    <ProfileArr />
                </Link>

                <Link to="" className="flex items-center justify-between w-full cursor-pointer hover:bg-[#f4f4f4] focus:bg-[#f4f4f4]   px-[22px] py-[6px]">
                    <div className="flex items-center gap-[8px]">
                        <MyCards />
                        <p className="text-darkGray text-xs font-normal">{t("auth.profile.my_cards")}</p>
                    </div>

                    <ProfileArr />
                </Link> */}
            {/* </div> */}

            {/* <div className="px-[22px]">
                <div className="border-b border-[#121] border-opacity-[0.25] w-full my-[12px]"></div>
            </div> */}

            <div className="flex flex-col">
                {/* <Link to="" className="flex items-center justify-between w-full cursor-pointer hover:bg-[#f4f4f4] focus:bg-[#f4f4f4]   px-[22px] py-[6px]">
                    <div className="flex items-center gap-[8px]">
                        <Support />
                        <p className="text-darkGray text-xs font-normal">{t("auth.profile.support")}</p>
                    </div>

                    <ProfileArr />
                </Link> */}

                <Link to="" className="flex items-center justify-between w-full cursor-pointer px-[22px] py-[6px] hover:bg-[#f4f4f4] focus:bg-[#f4f4f4]  " onClick={handleLogout}>
                    <div className="flex items-center gap-[8px]">
                        <Logout />
                        <p className="text-darkGray text-xs font-normal">{t("auth.profile.logout")}</p>
                    </div>

                    <ProfileArr />
                </Link>
            </div>
        </div>
    );
};

export default ProfilePopup;