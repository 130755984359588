import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import MainHeader from "../../components/layout/MainHeader";
import WebPopup from "../../components/layout/WebPopup";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import LostFoundForm from "../../components/city/LostFoundForm";
import Login from "../../components/auth/Login";
import Register from "../../components/auth/Register";
import ProfileCreated from "../../components/auth/ProfileCreated";
import FeedbackSuccess from "../../components/home/FeedbackSuccess";
import LoginWarn from "../../components/home/LoginWarn";
import ParticipateForm from "../../components/events/ParticipateForm";
import ForgotPassword from "../../components/auth/ForgotPassword";
import VerifyOtp from "../../components/auth/VerifyOtp";
import ResetPassword from "../../components/auth/ResetPassword";

const CityLayout = () => {
    const webOpen: boolean = useSelector((state: any) => state.ui.webOpen);
    const langOpen: boolean = useSelector((state: any) => state.ui.langOpen);
    const lostFound: boolean = useSelector((state: any) => state.data.lostFound);
    const loginOpen: boolean = useSelector((state: any) => state.data.loginOpen);
    const forgotPasswordOpen: boolean = useSelector((state: any) => state.data.forgotPasswordOpen);
    const verifyOtpOpen: boolean = useSelector((state: any) => state.data.verifyOtpOpen);
    const resetPasswordOpen: boolean = useSelector((state: any) => state.data.resetPasswordOpen);
    const registerOpen: boolean = useSelector((state: any) => state.data.registerOpen);
    const profileOpen: boolean = useSelector((state: any) => state.data.profileOpen);
    const feedbackSuccess: boolean = useSelector((state: any) => state.data.feedbackSuccess);
    const loginWarnOpen: boolean = useSelector((state: any) => state.data.loginWarnOpen);
    const participateOpen: boolean = useSelector((state: any) => state.data.participateOpen);
    const location = useLocation();

    useEffect(() => {
        if (webOpen || langOpen || lostFound || loginOpen || registerOpen || profileOpen || loginWarnOpen || feedbackSuccess || participateOpen || forgotPasswordOpen || verifyOtpOpen || resetPasswordOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [webOpen, langOpen, lostFound, loginOpen, registerOpen, profileOpen, feedbackSuccess, loginWarnOpen, participateOpen, forgotPasswordOpen, verifyOtpOpen, resetPasswordOpen]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className='w-full h-full bg-white dark:bg-[#272727]'>
            {webOpen && <WebPopup />}
            {lostFound && <LostFoundForm />}
            {loginOpen && <Login />}
            {forgotPasswordOpen && <ForgotPassword />}
            {verifyOtpOpen && <VerifyOtp />}
            {resetPasswordOpen && <ResetPassword />}
            {registerOpen && <Register />}
            {profileOpen && <ProfileCreated />}
            {feedbackSuccess && <FeedbackSuccess />}
            {loginWarnOpen && <LoginWarn />}
            {participateOpen && <ParticipateForm />}
            <MainHeader />
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
};

export default CityLayout;