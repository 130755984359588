/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { CheckEye, Eye, CloseBtn } from "../svg/AllSvg";
import useAxios from "../../shared/hooks/use-axios";
import { url } from "../../shared/utils/url";

const ResetPassword = () => {
    const resetPasswordOpen: boolean = useSelector((state: any) => state.data.resetPasswordOpen);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [hide, setHide] = useState<boolean>(true);
    const [confirmhide, setConfirmHide] = useState<boolean>(true);
    const [submit, setSubmit] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const popupRef = useRef<HTMLDivElement>(null);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setResetPasswordOpen(false))
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                dispatch(dataActions.setResetPasswordOpen(false));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    const handlePassword = () => {
        setHide(!hide);
    };

    const handleConfirmPassword = () => {
        setConfirmHide(!confirmhide);
    };

    const resetPasswordObj: any = {
        password: "",
        password2: "",
        email: localStorage.getItem("field_value")
    };

    const resetPasswordSchema = yup.object().shape({
        password: yup.string().matches(/^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t("auth.form.validation.wrong_password")).required(t("auth.form.validation.password")),
        password2: yup.string().oneOf([yup.ref("password"), ""], t("auth.form.validation.match_password")).required(t("auth.form.validation.confirm_password")),
        email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email"))
    });

    const handleSubmit = async (values: any) => {
        try {
            setSubmit(true)
            let obj = {
                new_password1: values.password,
                new_password2: values.password2,
                email: values.email
            }
            const res: any = await useAxios.post(`/${lang + url?.reset_password}`, obj);

            if (res && res?.status === 200) {
                dispatch(dataActions.setResetPasswordOpen(false))
                dispatch(dataActions.setLoginOpen(true))
                localStorage.removeItem("field_value")
                setSubmit(false)
            }
        } catch (error: any) {
            setSubmit(false)
            if (error && error?.response?.status === 400 && !!error?.response?.data?.message) {
                setError(error?.response?.data?.message?.toString())
            }
            console.error("error while reset password", error);
        }
    }

    return (
        <>
            {resetPasswordOpen &&
                <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[rgba(203,203,203,0.40)] backdrop-blur-[7.5px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative my-6 md:mx-auto bg-white rounded-[20px] flex flex-col lg:flex-row w-[320px] sm:w-[600px] md:w-[745px] lg:w-[906px] h-full lg:min-h-[600] shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]" ref={popupRef}>
                            <div className="w-full h-[250px] lg:h-auto lg:w-[427px] lg:max-h-[600px] relative">
                                <img src={require("../../assets/images/login-bg.png")} alt="login-bg" className="w-full h-[250px] lg:h-full object-cover rounded-t-[20px] lg:rounded-r-none lg:rounded-l-[20px] " />
                                <img src={require("../../assets/images/pattern3.png")} alt="pattern" className="absolute left-0 bottom-0 lg:rounded-bl-[20px]" />
                            </div>

                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="z-[1] cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" autoFocus={true} onClick={() => dispatch(dataActions.setResetPasswordOpen(false))}>
                                <CloseBtn />
                            </button>

                            <Formik
                                initialValues={resetPasswordObj}
                                validationSchema={resetPasswordSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, handleChange, handleBlur, errors }) => (
                                    <Form className="flex flex-col relative w-full lg:w-[calc(100%-427px)] lg:max-h-full items-start justify-center p-[20px] sm:p-[30px] lg:p-[47px]">
                                        <h1 className="text-primary dark:!text-primary text-xl sm:text-2xl 1xl:text-3xl font-bold font-caudex">{t("auth.resetPasswordTitle")}</h1>
                                        <p className="text-[#606060] text-xs sm:text-sm font-normal pt-[4px]">{t("auth.resetPasswordDesc")}</p>

                                        <div className="flex flex-col gap-[18px] pt-[20px] lg:pt-[30px] w-full">
                                            <div className="flex flex-col gap-[4px]">
                                                <label className="text-darkGray text-xs font-normal" htmlFor="password">{t("auth.form.field.password")}</label>
                                                <div className="rounded-[10px] bg-[#F4F4F9] hover:border-[#999] focus:border-[#999] border border-[#ECECEC] h-[40px] pl-[10px] pr-[40px] w-full relative flex items-center">
                                                    <input className="bg-[#F4F4F9] w-full text-secondaryText text-xs sm:text-sm font-normal h-full" type={hide ? "password" : "text"} name="password" value={values?.password} onBlur={handleBlur} onChange={handleChange} autoComplete="off" />

                                                    <div className="absolute right-[10px] top-[12px] cursor-pointer" onClick={handlePassword}>
                                                        {hide ? <CheckEye /> : <Eye />}
                                                    </div>
                                                </div>
                                                <ErrorMessage
                                                    name="password"
                                                    component="div"
                                                    className="text-red-500 text-xs"
                                                />
                                            </div>

                                            <div className="flex flex-col gap-[4px]">
                                                <label className="text-darkGray text-xs font-normal" htmlFor="confirm_password">{t("auth.form.field.confirm_password")}</label>
                                                <div className="rounded-[10px] bg-[#F4F4F9] hover:border-[#999] focus:border-[#999] border border-[#ECECEC] h-[40px] pl-[10px] pr-[40px] w-full relative flex items-center">
                                                    <input className="bg-[#F4F4F9] w-full   text-secondaryText text-xs sm:text-sm font-normal h-full" type={confirmhide ? "password" : "text"} name="password2" value={values?.password2} onBlur={handleBlur} onChange={handleChange} autoComplete="off" />

                                                    <div className="absolute right-[10px] top-[12px] cursor-pointer" onClick={handleConfirmPassword}>
                                                        {confirmhide ? <CheckEye /> : <Eye />}
                                                    </div>
                                                </div>
                                                <ErrorMessage
                                                    name="password2"
                                                    component="div"
                                                    className="text-red-500 text-xs"
                                                />
                                            </div>
                                            {(!!error?.password && !!!errors?.password && !!!errors?.password2) && <div className="text-red-500 text-xs">{error}</div>}
                                        </div>

                                        <button type="submit" className="rounded-[10px]  h-[40px] shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm font-bold bg-primary disabled:opacity-[0.3] disabled:cursor-not-allowed w-full mt-[25px]  hover:shadow-[0_0_6px_0px_#61095A] focus:shadow-[0_0_6px_0px_#61095A] " disabled={!submit ? false : true} >{t("city.lost_found.report_lost_item_form.form.btn")}</button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div >}
        </>
    );
};

export default ResetPassword;